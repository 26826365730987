.m-profileCard {
    @include adjust-flex(flex-start, center);
    border-radius: 8px;
    border: 1px solid $anti-flash-white;
    padding: rem(8);
    cursor: pointer;
    transition: all $transition linear;

    &:hover {
        border: 1px solid $la-salle-green;
    }

    &-active {
        border: 2px solid $la-salle-green !important;
    }

    &_img {
        width: 100%;
        max-width: rem(120);
        border-radius: rem(4);
        overflow: hidden;
        flex-grow: 1;
        flex-shrink: 1;
        margin-right: rem(8);
    }
}
