.a-text-area {
    $root: &;
  
    &_errorMessage {
        color: $red-orange;
        font-weight: 500;
        margin-top: rem(2);
        @include font-base(12, 16);
    }
  
    &_label {
        @include font-base(14, 20);
        color: $eerie-black;
        display: inline-block;
        font-weight: 600;
        margin-bottom: rem(12);
    }
  
    &_inputele {
        border: 1px solid $gainsboro;
        border-radius: rem(4);
        box-sizing: border-box;
        font-weight: 500;
        color: $gray;
        margin: 0;
        outline: none;
        padding: rem(10) rem(16);
        resize: none;
        transition: all $transition linear;
        width: 100%;
        @include font-base(14, 20);
        font-family: $font-inter;

        &::placeholder {
            color: $manatee;
            font-weight: 500;
            font-family: $font-inter;
        }
  
        &:disabled {
            cursor: not-allowed;
            background-color: $gainsboro;
            border: 1px solid $gainsboro;
        }
    }
  
    &-error {
        #{$root}_inputele {
            border: 1px solid $red-orange;
            color: $red-orange;
            background-color: $seashell;
        }
        #{$root}_label {
            color: $red-orange;
        }
    }

}
