// fonts
$font-path: '~assets/fonts/';

$font-quicksand-light: 'Quicksand-Light', serif;
$font-quicksand-regular: 'Quicksand-Regular', serif;
$font-quicksand-medium: 'Quicksand-Medium', serif;
$font-quicksand-semibold: 'Quicksand-SemiBold', serif;
$font-quicksand-bold: 'Quicksand-Bold', serif;
$font-inter: 'Inter', serif;

// transition
$transition: 0.25s;
$transition-medium: 0.3s;
$transition-long: 0.4s;

// z-index
$z-header: 100;
$z-modal: 101;
$z-1: 1;
$z-loading: 999;

// space
$height-header-desktop: 100;
$height-header-desktop-scroll: 76;
$height-header-tablet-down: 65;

$standard-viewport: 1366px;
