.a-image {
    display: block;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    transition: $transition-long ease;
    width: 100%;

    img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &-inherit {
        img {
            object-fit: inherit;
        }
    }
  
    &-initial {
        img {
            object-fit: initial;
        }
    }
  
    &-contain {
        img {
            object-fit: contain;
        }
    }

    &-1x1 {
        @include aspect-ratio(1, 1);
    }

    &-98x120 {
        @include aspect-ratio(98, 120);
    }

    &-382x506 {
        @include aspect-ratio(382, 506);
    }

    &-480x640 {
        @include aspect-ratio(480, 640);
    }
}
