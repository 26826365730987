// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$sea-green: rgb(51, 141, 81); //#338d51
$isabelline: rgb(230, 241, 234); // #e6f1ea
$la-salle-green: rgb(0, 113, 37); //#007125
$light-gray: rgb(204, 227, 211); //#cce3d3
$honeydew: rgb(234, 250, 245); //#eafaf5
$cosmic-latte: rgb(255, 250, 230); //#fffae6
$golden-poppy: rgb(254, 196, 0); //#fec400
$stormcloud: rgb(88, 96, 99); // #586063
$anti-flash-white: rgb(239, 240, 240) ; // #eff0f0
$mountain-meadow: rgb(41, 204, 151); //#29cc97
$alice-blue: rgb(234, 240, 255); //#eaf0ff
$seashell: rgb(255, 238, 238); //#ffeeee
$red-orange: rgb(255, 77, 79); //#ff4d4f
$crayola: rgb(40, 95, 250); //#285ffa
$outer-space: rgb(66, 72, 74); // #42484a
$gray: rgb(121, 128, 130); // #798082
$snow: rgb(250, 250, 250); //#fafafa
$eerie-black: rgb(22, 24, 25); // #161819
$gainsboro: rgb(222, 223, 224); // #dedfe0
$manatee: rgb(155, 160, 161) ;// #9ba0a1
$pastel-gray: rgb(205, 205, 205); //#cdcdcd
$manatee: rgb(155, 160, 161); //#9ba0a1
$ghost-white: rgb(249, 249, 249); // #f9f9f9
// Overlays
$white-overlay04: rgba($white, 0.4);

// Linears
