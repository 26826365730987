$modifiers: (
    successful: (
        color: $white,
        background: $mountain-meadow,
    ),
    denied: (
        color: $red-orange,
        background: $seashell,
    ),
    preventive: (
        color: $crayola,
        background: $alice-blue,
    ),
    pending: (
        color: $stormcloud,
        background: $anti-flash-white,
    ),
);

.a-button {
    $root: &;
    @include reset-button();
    @include adjust-flex();
    background-color: transparent;
    padding: rem(10) rem(24);
    border-radius: rem(4);
    width: 100%;
    transition: opacity $transition ease-in;

    &_icon {
        flex-shrink: 1;
        margin-right: rem(8);
    }

    @each $name, $value in $modifiers {
        &-#{$name} {
            background-color: map-get($value, 'background');
            #{$root}_text {
                .a-text {
                    color: map-get($value, 'color');
                }
            }
        }
    }

    &:hover {
        opacity: 0.7;
    }
}
