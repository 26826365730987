.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    $icons: 'checkmark', 'bookmark', 'dismiss' , 'close'; // Add more icon
    $max-sizes: 50;
    @each $icon in $icons {
        &-#{$icon} {
            background-image: url('~assets/icons/ic_#{$icon}.svg');
        }
    }

    @for $i from 1 to $max-sizes {
        @if $i % 2 == 0 {
            &-#{$i} {
                width: rem($i);
                height: rem($i);
            }
        }
    }
}

