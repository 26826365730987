.a-heading {
    display: block;
    margin: 0;
    width: 100%;
    font-family: $font-quicksand-medium;

    &-20x28 {
        @include font-base(20, 28);
        @include mobile-down {
            @include font-base(16,24);
        }
    }

    &-24x32 {
        @include font-base(24, 32);
        @include mobile-down {
            @include font-base(20, 28);
        }
    }

    @include style-color;
    @include style-general;
    @include style-font-weight;
    @include style-font;
}
