.o-informationDetail {
    &_title {
        @include adjust-flex(flex-start, center);

        &-label {
            margin-right: rem(12);
        }
    }

    &_position {
        background: $isabelline;
        border-radius: rem(4);
        padding: rem(4) rem(12);
        margin-left: rem(4);
    }

    &_positionWrapper {
        @include adjust-flex(flex-end, center);
        flex-wrap: wrap;
    }

    &_content {
        display: flex;
        width: 100%;
        margin-top: rem(12);
        @include desktop-down {
            display: block;
        }
    }

    &_right {
        max-width: 60%;
        flex: 0 0 60%;
        display: flex;
        flex-direction: column;
        @include desktop-down {
            max-width: 100%;
            flex: 0 0 100%;
            margin-top: rem(12);
            margin-left: 0;
        }

        .o-carousel {
            height: 100%;
            margin: auto;
        }
    }

    &_left {
        padding: 0 61px;
        max-width: 40%;
        flex: 0 0 40%;
        position: relative;
        cursor: pointer;

        &-slide {
            position: absolute;
            background: rgba(9, 10, 10, 0.6);
            border-radius: 200px;
            padding: rem(2) rem(16);
            bottom: rem(16);
            left: 50%;
            transform: translateX(-50%);
        }

        @include desktop-down {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    &_wrapText {
        @include adjust-flex( space-between, center);
        margin-top: rem(12);
    }

    &_input {
        margin-top: rem(16);
    }

    &_wrapNote {
        margin-top: rem(8);
    }

    &_note {
        margin-top: rem(4);
    }

    &_button {
        @include adjust-flex(flex-end, center); 
        margin-top: rem(85);
        margin-bottom: rem(8);

        @include desktop-down {
            margin-top: rem(18);
        }
        @include mobile-down {
            display: block;
            margin-top: rem(10);
        }
    }

    &_item {
        margin-left: rem(8);

        @include mobile-down {
            margin-left: 0;
            margin-top: rem(8);
        }
    }
}
