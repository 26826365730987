.p-profile {
    display: flex;
    flex-direction: column;

    @include tablet-up {
        @media (min-height: 720px) {
            height: 100vh;
        }
    }

    &_info {
        position: relative;
    }

    &_loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $z-loading;
        background-color: $white-overlay04;
        @include adjust-flex();
    }

    &_invalid {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include adjust-flex();
    }

    &_wrapContent {
        padding: 0 rem(16);
        @include tablet-up {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
    }

    &_tabPanel {
        @include tablet-up {
            flex: 1;
            max-height: 100%;
            overflow: hidden;
            padding-top: rem(16);
        }
        background-color: $ghost-white;
    }

    &_content {
        position: relative;
        @include adjust-flex(space-between, flex-start);
        border-radius: 0 rem(8) 0 0;

        @include tablet-up {
            height: 100%;
        }

        @include tablet-down {
            display: block;
        }
    }

    &_listProfile {
        flex: 0 0 30%;
        display: flex;
        flex-direction: column;
        padding-left: rem(16);

        @include tablet-up {
            height: 100%;
        }

        @include tablet-down {
            padding-left: 0;
            flex: 0 0 100%;
            max-height: rem(480);
        }
     
    }

    &_informationDetail {
        padding-left: rem(16);
        flex: 0 0 70%;
        max-width: 70%;
        display: flex;
        flex-direction: column;
        padding-right: rem(16);

        @include tablet-up {
            height: 100%;
            overflow-y: auto;
            @include scroll-bars(6, $pastel-gray, $isabelline);
        }

        @include tablet-down {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 0;
            margin-top: rem(16);
            padding-right: 0;
        }
    }
}
