.t-table {
    width: 100%;
    border-spacing: 0;

    thead {
        th {
            padding: rem(4);
            background-color: $la-salle-green;
            border: 1px solid $white;

            &:first-child {
                background-color: $isabelline;
                border-color: $light-gray;
            }
        }
    }

    tbody {
        td {
            padding: rem(4);
            border: 1px solid $anti-flash-white;

            &:first-child {
                border-color: $light-gray;
                background-color: $isabelline;
            }
        }
    }
}
