.o-listProfileCard {
    overflow-y: auto;
    @include scroll-bars(6, $pastel-gray, $isabelline);

    &_item {
        &:not(&:first-child) {
            margin-top: rem(8);
        }
    }
}
