.a-statusLabel {
    border-radius: rem(4);
    padding: rem(4) rem(16);

    &-green {
        background: $honeydew;
        border: 1px solid $mountain-meadow;

        .a-text {
            color: $mountain-meadow;
        }
    }

    &-yellow {
        background: $cosmic-latte;
        border: 1px solid $golden-poppy;

        .a-text {
            color: $golden-poppy;
        }
    }

    &-gray {
        background: $anti-flash-white;
        border: 1px solid $stormcloud;

        .a-text {
            color: $stormcloud;
        }
    }

    &-red {
        background: $seashell;
        border: 1px solid $red-orange;

        .a-text {
            color: $red-orange;
        }
    }
}
