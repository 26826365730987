.o-tabs {
    $root: &;
    @include adjust-flex(flex-start, center);
    border-bottom: 1px solid $snow;

    &_labels {
        @include adjust-flex(flex-start, center);
        overflow-x: auto;
        @include scroll-bars(4);
        background-color: $snow;
        border-top-right-radius: rem(8);
        overflow: hidden;
        padding: rem(8) rem(12) rem(0);
        border: 1px solid $anti-flash-white;
    }
  
    &_label {
        color: $outer-space;
        font-weight: 700;
        transition: all $transition linear;
        white-space: nowrap;
        @include font-base(13, 18);
    }   
  
    &_tab {
        border-bottom: 1px solid transparent;
        cursor: pointer;
        position: relative;
        transition: background-color $transition linear;
        border-top-left-radius: rem(8);
        border-top-right-radius: rem(8);
        overflow: hidden;
        padding: rem(8) rem(16) rem(12);

        &:hover,
        &-active {
            background-color: $la-salle-green;
            // border-bottom: 1px solid $la-salle-green;
            #{$root}_label {
                color: $white;

                &::after {
                    visibility: hidden;
                }
            }
        }

        &:not(:last-of-type) {
            #{$root}_label::after {
                content: '';
                height: 34px;
                position: absolute;
                width: 1px;
                background: $gainsboro;
                right: 0;
                top: 50%;
                transform: translateY(-50%);    
            }
        }
  
    }
  
    &_panel {
        display: none;
  
  
        &-active {
            display: block;
        }
    }
}
