.t-projectProfileInfo {
    padding: rem(16) rem(16) rem(4);
    @include adjust-flex(space-between, flex-start);
    @include tablet-down {
        flex-direction: column;
        padding: rem(8);
    }

    &_left {
        flex: 0 0 calc(899 / 1366 * 100%);
        @include desktop-down {
            flex-basis: 50%;
        }
    }

    &_right {
        flex: 1 1 auto;
        @include tablet-down {
            margin-top: rem(12);
        }
    }

    &_left,
    &_right {
        @include tablet-down {
            width: 100%;

        }
    }

    &_row {
        @include adjust-flex(flex-start);
        margin-top: rem(12);

        &-label {
            flex: 1 0 auto;
            margin-right: rem(16);
        }

        &:first-child {
            margin-top: rem(8);
        }
    }
}
