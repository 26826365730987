.a-text {
    $root: &;
    font-family: $font-quicksand-medium;
    @include style-color();
    @include style-general();
    @include style-font-weight();
    @include style-font();

    &-12x16 {
        @include font-base(12,16);
    }

    &-13x18 {
        @include font-base(13,18);
    }

    &-14x20 {
        @include font-base(14,20);
    }

    &-16x24 {
        @include font-base(16,24);
        @include mobile-down {
            @include font-base(14, 20);
        }
    }

    &-20x28 {
        @include font-base(20, 28);
        @include mobile-down {
            @include font-base(16,24);
        }
    }
}
